<template>
  <div class="info">
    <div class="container">
      <div class="row">
        <div class="info__list row" v-if="infoBlock">
          <div class="info__item col-md-4">
            <div class="info__item-icon">
              <inline-svg :src="require(`@/assets/images/svg/info-ic_1.svg`)"/>
            </div>
            <div class="info__item-title">
              {{infoBlock['cooperation_title']}}
            </div>
            <div class="info__item-span">
              {{infoBlock['cooperation_count']}}
            </div>
            <div class="info__item-text">
              {{infoBlock['cooperation_description']}}
            </div>
          </div>
          <div class="info__item col-md-4">
            <div class="info__item-icon">
              <inline-svg :src="require(`@/assets/images/svg/info-ic_2.svg`)"/>
            </div>
            <div class="info__item-title">
              {{infoBlock['wholesale_title']}}
            </div>
            <div class="info__item-span">
              {{infoBlock['wholesale_count']}}
            </div>
            <div class="info__item-text">
              {{infoBlock['wholesale_description']}}
            </div>
          </div>
          <div class="info__item col-md-4">
            <div class="info__item-icon">
              <inline-svg :src="require(`@/assets/images/svg/info-ic_3.svg`)"/>
            </div>
            <div class="info__item-title">
              {{infoBlock['retail_title']}}
            </div>
            <div class="info__item-span">
              {{infoBlock['retail_count']}}
            </div>
            <div class="info__item-text">
              {{infoBlock['retail_description']}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['infoBlock'],
  data(){
    return {}
  },
}
</script>
<style lang="scss" scoped>
  @import "src/assets/styles/styles";
  .info{
    padding: 95px 0;
    background-image: url("~@/assets/images/info-bg.png");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center -50px;
    @media screen and (max-width: 720px){
      padding: 40px 20px;
    }
    &__item{
      @media screen and (max-width: 991px){
        margin-bottom: 30px;
      }
    }
    &__item-icon{
      margin-bottom: 30px;
      svg{
        fill: $grey;
        height: 50px;
        width: 50px;
        object-fit: contain;
      }
    }
    &__item-title{
      font-family: 'Mont', Arial;
      font-size: 24px;
      font-weight: 900;
      margin-bottom: 30px;
      text-transform: uppercase;
      line-height: 29px;
      min-height: 60px;
    }
    &__item-span{
      font-family: 'Montserrat', Arial;
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 9px;
      margin-bottom: 35px;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background-color: $grey;
      }
      &:after{
        content: '';
        position: absolute;
        width: 45%;
        height: 4px;
        left: 0;
        bottom: 0;
        background-color: $yellow;
      }
    }
    &__item-text{
      font-family: 'Inter', Arial;
      padding-right: 15px;
      line-height: 21px;
      font-size: 16px;
      font-weight: 300;
    }
  }
</style>
